import {
  Button,
  EButtonSize,
  EButtonTheme,
  EButtonVariant,
} from '@front/shared/ds';
import React, { ReactNode, useEffect, useState } from 'react';

import { TSpaceWithDynamicTextRow } from './index';
import { ReactComponent as SchemeArrows } from '../../../../public/images/scheme-arrows.svg';

export type TSchemeProps = TSpaceWithDynamicTextRow & {
  onMouseEnter?: (id: string) => void;
};

export const Scheme: React.FC<TSchemeProps> = props => {
  const [schemeScale, setSchemeScale] = useState(1);
  const schemeWidth = 816;
  useEffect(() => {
    const handleResize = (): void => {
      if (window.outerWidth <= 848) {
        setSchemeScale((window.outerWidth - 32) / schemeWidth);
      } else {
        setSchemeScale(1);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    // Cleanup function to remove the event listener when component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const {
    exchange,
    exchangeList,
    spotBroker,
    spotBrokerList,
    cost,
    setupTime,
    onMouseEnter,
  } = props;

  const renderButtons = (list: typeof exchangeList): ReactNode => {
    return list.map(button => (
      <Button
        key={button.id}
        size={EButtonSize.Small}
        variant={EButtonVariant.Contained}
        theme={EButtonTheme.Secondary}
        className='hyphens-auto leading-normal'
        onMouseEnter={() => {
          if (onMouseEnter) {
            onMouseEnter(button.id || '');
          }
        }}
      >
        {button.title}
      </Button>
    ));
  };
  return (
    <div
      className='origin-top p-8'
      style={{
        transform: `scale(${schemeScale})`,
        height: `${schemeWidth * schemeScale * 0.735295}px`,
      }}
    >
      <div className='mb-4 mt-5 text-lg font-semibold leading-normal text-interface-1000'>
        {cost}
      </div>
      <div className='relative h-[26.25rem] w-[47rem] overflow-hidden'>
        <SchemeArrows className='absolute start-0 top-0 size-full text-accent-500 rtl:-scale-x-100' />
        <div className='relative grid size-full grid-cols-[1fr_1fr_9.75rem] grid-rows-[3rem_1fr_1fr_1fr] gap-2 pb-6 pe-2 ps-6 pt-[2.38rem]'>
          {renderButtons(exchangeList.slice(0, 2))}
          <div className='flex items-center justify-center rounded-xl bg-surface-800 text-sm font-medium leading-normal text-interface-50'>
            {exchange}
          </div>
          <div className='col-span-2 row-span-3'>
            <div className='grid h-full grid-cols-[1fr_1fr_9.75rem] grid-rows-[3rem_1fr_1fr] gap-2 pe-12 pt-[6.75rem]'>
              {renderButtons(spotBrokerList.slice(0, 2))}
              <div className='flex items-center justify-center rounded-xl bg-accent-500 text-sm font-medium leading-normal text-interface-50'>
                {spotBroker}
              </div>
              <div className='col-span-2 row-span-2'></div>
              {renderButtons(spotBrokerList.slice(2, 4))}
            </div>
          </div>
          {renderButtons(exchangeList.slice(2, 5))}
        </div>
      </div>
      <div className='mb-5 ms-auto mt-4 w-40 text-center text-lg font-semibold leading-normal text-interface-1000'>
        {setupTime}
      </div>
    </div>
  );
};
