import { useSectionAnimationContext } from '@front/master';
import { prepareDefaultCardFromRawData } from '@front/master/components/blocks/DefaultCardsConnected';
import { useAppDispatch } from '@front/master/store/store.hooks';
import {
  EContainerVariant,
  Container,
  useSequentialAnimations,
} from '@front/shared/ds';
import { Section } from '@shared/master-types';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import React, { useMemo } from 'react';

import { DefaultCard } from '../CustomDefaultCard';
import { useMedia } from 'react-use';
import { baseTailwindConfig } from '@front/shared';

export type TFourCardsConnectedProps = Extract<
  Required<Section>['rows'][number],
  { blockType: 'plates-default-card' }
> & { customType: 'b2broker-main-4-cards' };

type TCustomFourCardsRowProps = {
  row: TFourCardsConnectedProps;
};

export const CustomFourCardsRow: React.FC<TCustomFourCardsRowProps> = ({
  row,
}) => {
  const { cards = [] } = row;
  const appDispatch = useAppDispatch();
  const router = useRouter();
  const currentLocale = String(router.query.locale || '');

  const { isSectionAnimationSequenceReady } = useSectionAnimationContext()!;

  const isDesktop = useMedia(
    `(min-width: ${baseTailwindConfig.screens.desktop})`,
    true,
  );

  const { ref: ref1, transitionClasses: transitionClasses1 } =
    useSequentialAnimations({
      count: 2,
      delay: 200,
      startClasses: 'opacity-0 translate-y-8',
      finishClasses: 'opacity-100',
      enabled: isDesktop && isSectionAnimationSequenceReady,
      threshold: 0.4,
    });
  const { ref: ref2 } = useSequentialAnimations({
    count: 2,
    delay: 200,
    startClasses: 'opacity-0 translate-y-8',
    finishClasses: 'opacity-100',
    threshold: 0.4,
    enabled: isDesktop,
  });

  const banners: React.ComponentProps<typeof DefaultCard>[] = useMemo(() => {
    return cards.map(card =>
      prepareDefaultCardFromRawData(card, currentLocale, appDispatch),
    );
  }, [cards, currentLocale, appDispatch]);

  return (
    <Container variant={EContainerVariant.Default}>
      <div className='flex flex-col gap-4'>
        <div className={clsx('grid gap-4 md:grid-cols-[2fr_1fr]')} ref={ref1}>
          {banners.slice(0, 2).map((card, index) => (
            <div
              key={card.title}
              className={clsx(
                'max-h-full',
                'transition-all duration-300 ease-in',
                (isDesktop && transitionClasses1[index]) || '',
              )}
            >
              <DefaultCard {...card} />
            </div>
          ))}
        </div>
        <div className={clsx('grid gap-4 md:grid-cols-2')} ref={ref2}>
          {banners.slice(2).map((card, index) => (
            <div
              key={card.title}
              className={clsx(
                'max-h-full',
                'transition-all duration-300 ease-in',
                (isDesktop && transitionClasses1[index]) || '',
              )}
            >
              <DefaultCard {...card} />
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
};
