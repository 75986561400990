import { baseTailwindConfig } from '@front/shared';
import clsx from 'clsx';
import React, { useState } from 'react';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { AwardSingle, TAwardSingleProps } from './AwardSingle';
import { useMediaSlidesCount } from './hooks/useMediaSlidesCount';
import { ReactComponent as IconNext } from '../../../../public/icons/components/awards/next-button.svg';
import { ReactComponent as IconPrev } from '../../../../public/icons/components/awards/prev-button.svg';
import { useDirection } from '../../../hooks/';

export type TAwardsProps = {
  title?: string;
  list?: {
    listTitle?: string;
    listSlides?: TAwardSingleProps[];
  }[];
};

export const Awards: React.FC<TAwardsProps> = ({ title, list }) => {
  const prevButton = React.createRef<HTMLDivElement>();
  const nextButton = React.createRef<HTMLDivElement>();

  const [hoveredItemId, setHoveredItemId] = useState<null | string>(null);
  const slidesCount = useMediaSlidesCount();

  const swiperButtonsClasses =
    'h-[2rem] w-[2rem] rounded-[50%] bg-light-control-50/90 p-2 flex items-center justify-center cursor-pointer transition opacity-90 hover:opacity-100 hover:bg-light-control-50 text-control-600';

  const setHoverNull = (): void => {
    setHoveredItemId(null);
  };

  const setHoverActive =
    (singleSlideId: string | undefined, rowIndex: number) => () => {
      singleSlideId && setHoveredItemId(rowIndex + singleSlideId);
    };

  const direction = useDirection();

  return (
    <>
      <div className='relative flex w-full justify-between px-4'>
        {title && (
          <h3 className='w-full border-b border-surface-200 pb-4 text-start text-sm font-medium uppercase leading-relaxed tracking-[0.28rem] text-control-600'>
            {title}
          </h3>
        )}
        <div className='absolute bottom-[-0.938rem] end-0 start-auto hidden gap-4 bg-surface-100 pe-4 ps-8 md:flex md:flex-row-reverse rtl:flex-row'>
          <div
            className={clsx('awards-slider-prev-btn', swiperButtonsClasses)}
            ref={nextButton}
            onClick={setHoverNull}
          >
            <IconNext />
          </div>
          <div
            ref={prevButton}
            className={clsx('awards-slider-next-btn', swiperButtonsClasses)}
            onClick={setHoverNull}
          >
            <IconPrev />
          </div>
        </div>
      </div>
      <div className='px-4 pt-6 md:pt-[2.313rem]'>
        <div className='overflow-visible'>
          <Swiper
            key={direction}
            navigation={{
              nextEl: '.awards-slider-prev-btn',
              disabledClass: '!bg-transparent !opacity-40',
              prevEl: '.awards-slider-next-btn',
            }}
            className='relative flex overflow-visible'
            modules={[Navigation]}
            slidesPerView={5}
            breakpoints={{
              [parseInt(baseTailwindConfig.screens.md)]: {
                slidesPerView: 8,
              },
              [parseInt(baseTailwindConfig.screens.lg)]: {
                slidesPerView: 12,
              },
              [parseInt(baseTailwindConfig.screens.xl)]: {
                slidesPerView: 16,
              },
            }}
            spaceBetween={32}
            wrapperClass={'flex'}
            loop={false}
            pagination={false}
            scrollbar={false}
          >
            {list &&
              list.map((item, rowIndex) =>
                item.listSlides?.map((singleSlide, index) => (
                  <SwiperSlide
                    onMouseOver={setHoverActive(singleSlide.id, rowIndex)}
                    onTouchStart={setHoverActive(singleSlide.id, rowIndex)}
                    key={singleSlide.id}
                    className='group relative flex w-full max-w-12 flex-col pb-20'
                  >
                    {({ isActive }) => (
                      <AwardSingle
                        isActive={
                          !hoveredItemId
                            ? isActive
                            : typeof singleSlide.id !== 'undefined' &&
                              hoveredItemId === rowIndex + singleSlide.id
                        }
                        listTitle={index === 0 ? item.listTitle : ''}
                        logo={singleSlide.logo}
                        link={singleSlide?.link}
                        description={singleSlide?.description}
                        title={singleSlide?.title}
                      />
                    )}
                  </SwiperSlide>
                )),
              )}

            {new Array(slidesCount - 1).fill(0).map((_, index) => (
              <SwiperSlide key={`empty_slide_${index}`} className='invisible' />
            ))}
          </Swiper>
        </div>
      </div>
    </>
  );
};
