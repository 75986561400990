import { ReactNode } from 'react';

import { ReactComponent as FacebookLogo } from '../../public/icons/facebook-logo.svg';
import { ReactComponent as InstagramLogo } from '../../public/icons/instagram-logo.svg';
import { ReactComponent as LinkedInLogo } from '../../public/icons/linked-in-logo.svg';
import { ReactComponent as TelegramLogo } from '../../public/icons/telegram-logo.svg';
import { ReactComponent as TwitterLogo } from '../../public/icons/twitter-logo.svg';
import { ReactComponent as WeiboLogo } from '../../public/icons/weibo-logo.svg';
import { ReactComponent as WeixinLogo } from '../../public/icons/weixin-logo.svg';
import { ReactComponent as YoukuLogo } from '../../public/icons/youku-logo.svg';
import { ReactComponent as YoutubeLogo } from '../../public/icons/youtube-logo.svg';

const getSocialIcon = (
  type:
    | 'telegram'
    | 'linkedIn'
    | 'facebook'
    | 'youtube'
    | 'instagram'
    | 'twitter'
    | 'weibo'
    | 'weixin'
    | 'youku'
    | string,
  className = 'aspect-square h-5 w-5 object-contain object-center transition hover:text-control-650 2xl:h-4 2xl:w-4',
): ReactNode => {
  switch (type) {
    case 'twitter':
      return <TwitterLogo className={className} />;
    case 'facebook':
      return <FacebookLogo className={className} />;
    case 'youtube':
      return <YoutubeLogo className={className} />;
    case 'instagram':
      return <InstagramLogo className={className} />;
    case 'linkedIn':
      return <LinkedInLogo className={className} />;
    case 'telegram':
      return <TelegramLogo className={className} />;
    case 'weibo':
      return <WeiboLogo className={className} />;
    case 'weixin':
      return <WeixinLogo className={className} />;
    case 'youku':
      return <YoukuLogo className={className} />;
    default:
      return null;
  }
};

export default getSocialIcon;
