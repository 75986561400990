import { checkJurisdictionExist } from '@front/shared/utils';
import { Jurisdiction } from '@shared/master-types';

export interface IGetJurisdictionPathProps {
  jurisdictionList?: Jurisdiction[];
  jurisdiction?: string;
  partOfLink: string;
}

export default function getJurisdictionPath(
  props: IGetJurisdictionPathProps,
): string | undefined {
  const { jurisdictionList, jurisdiction, partOfLink } = props;

  const isJurisdictionExist = checkJurisdictionExist({
    jurisdictionList,
    jurisdiction,
  });

  const isPartOfLinkExist = checkJurisdictionExist({
    jurisdictionList,
    jurisdiction: partOfLink,
  });

  if (isJurisdictionExist) {
    return isPartOfLinkExist ? jurisdiction : `${jurisdiction}/${partOfLink}`;
  }

  return partOfLink;
}
