import { BlogArticle } from '@shared/master-types';

import { PaginatedDocs } from 'payload/dist/mongoose/types';
import { Where } from 'payload/types';

import { baseApi } from '../../store/store.constants';

export const blogPostsApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: build => ({
    getBlogArticles: build.query<
      PaginatedDocs<BlogArticle>,
      | {
          locale?: string;
          depth?: number;
          draft?: boolean;
          page?: number;
          limit?: number;
          where?: Where;
        }
      | undefined
    >({
      query: params => ({
        url: '/blog-articles',
        params: params ?? undefined,
      }),
    }),
  }),
});

export const {
  endpoints: { getBlogArticles },
  useGetBlogArticlesQuery,
} = blogPostsApi;
