export interface ICountryInfo {
  name: string;
  iso2: string;
  dialCode: string;
  iso3: number;
  emoji?: string;
}

export const COUNTRY_CODES: readonly Readonly<ICountryInfo>[] = [
  { name: 'Afghanistan (‫افغانستان‬‎)', iso2: 'af', dialCode: '93', iso3: 4 },
  { name: 'Albania (Shqipëri)', iso2: 'al', dialCode: '355', iso3: 8 },
  { name: 'Algeria (‫الجزائر‬‎)', iso2: 'dz', dialCode: '213', iso3: 12 },
  { name: 'American Samoa', iso2: 'as', dialCode: '1684', iso3: 16 },
  { name: 'Andorra', iso2: 'ad', dialCode: '376', iso3: 20 },
  { name: 'Angola', iso2: 'ao', dialCode: '244', iso3: 24 },
  { name: 'Anguilla', iso2: 'ai', dialCode: '1264', iso3: 660 },
  { name: 'Antarctica', iso2: 'aq', dialCode: '672', iso3: 10 },
  { name: 'Antigua and Barbuda', iso2: 'ag', dialCode: '1268', iso3: 28 },
  { name: 'Argentina', iso2: 'ar', dialCode: '54', iso3: 32 },
  { name: 'Armenia (Հայաստան)', iso2: 'am', dialCode: '374', iso3: 51 },
  { name: 'Aruba', iso2: 'aw', dialCode: '297', iso3: 533 },
  { name: 'Australia', iso2: 'au', dialCode: '61', iso3: 36 },
  { name: 'Austria (Österreich)', iso2: 'at', dialCode: '43', iso3: 40 },
  { name: 'Azerbaijan (Azərbaycan)', iso2: 'az', dialCode: '994', iso3: 31 },
  { name: 'Bahamas', iso2: 'bs', dialCode: '1242', iso3: 44 },
  { name: 'Bahrain (‫البحرين‬‎)', iso2: 'bh', dialCode: '973', iso3: 48 },
  { name: 'Bangladesh (বাংলাদেশ)', iso2: 'bd', dialCode: '880', iso3: 50 },
  { name: 'Barbados', iso2: 'bb', dialCode: '1246', iso3: 52 },
  { name: 'Belarus (Беларусь)', iso2: 'by', dialCode: '375', iso3: 112 },
  { name: 'Belgium (België)', iso2: 'be', dialCode: '32', iso3: 56 },
  { name: 'Belize', iso2: 'bz', dialCode: '501', iso3: 84 },
  { name: 'Benin (Bénin)', iso2: 'bj', dialCode: '229', iso3: 204 },
  { name: 'Bermuda', iso2: 'bm', dialCode: '1441', iso3: 60 },
  { name: 'Bhutan (འབྲུག)', iso2: 'bt', dialCode: '975', iso3: 64 },
  { name: 'Bolivia', iso2: 'bo', dialCode: '591', iso3: 68 },
  {
    name: 'Bosnia and Herzegovina (Босна и Херцеговина)',
    iso2: 'ba',
    dialCode: '387',
    iso3: 70,
  },
  { name: 'Botswana', iso2: 'bw', dialCode: '267', iso3: 72 },
  { name: 'Bouvet Island', iso2: 'bv', dialCode: '47', iso3: 74 },
  { name: 'Brazil (Brasil)', iso2: 'br', dialCode: '55', iso3: 76 },
  {
    name: 'British Indian Ocean Territory',
    iso2: 'io',
    dialCode: '246',
    iso3: 86,
  },
  { name: 'British Virgin Islands', iso2: 'vg', dialCode: '1284', iso3: 92 },
  { name: 'Brunei', iso2: 'bn', dialCode: '673', iso3: 96 },
  { name: 'Bulgaria (България)', iso2: 'bg', dialCode: '359', iso3: 100 },
  { name: 'Burkina Faso', iso2: 'bf', dialCode: '226', iso3: 854 },
  { name: 'Burundi (Uburundi)', iso2: 'bi', dialCode: '257', iso3: 108 },
  { name: 'Cambodia (កម្ពុជា)', iso2: 'kh', dialCode: '855', iso3: 116 },
  { name: 'Cameroon (Cameroun)', iso2: 'cm', dialCode: '237', iso3: 120 },
  { name: 'Canada', iso2: 'ca', dialCode: '1', iso3: 124 },
  { name: 'Cape Verde (Kabu Verdi)', iso2: 'cv', dialCode: '238', iso3: 132 },
  { name: 'Caribbean Netherlands', iso2: 'bq', dialCode: '599', iso3: 535 },
  { name: 'Cayman Islands', iso2: 'ky', dialCode: '1345', iso3: 136 },
  {
    name: 'Central African Republic (République centrafricaine)',
    iso2: 'cf',
    dialCode: '236',
    iso3: 140,
  },
  { name: 'Chad (Tchad)', iso2: 'td', dialCode: '235', iso3: 148 },
  { name: 'Chile', iso2: 'cl', dialCode: '56', iso3: 152 },
  { name: 'China (中国)', iso2: 'cn', dialCode: '86', iso3: 156 },
  { name: 'Christmas Island', iso2: 'cx', dialCode: '61', iso3: 162 },
  { name: 'Cocos (Keeling) Islands', iso2: 'cc', dialCode: '61', iso3: 166 },
  { name: 'Colombia', iso2: 'co', dialCode: '57', iso3: 170 },
  { name: 'Comoros (‫جزر القمر‬‎)', iso2: 'km', dialCode: '269', iso3: 174 },
  {
    name: 'Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)',
    iso2: 'cd',
    dialCode: '243',
    iso3: 180,
  },
  {
    name: 'Congo (Republic) (Congo-Brazzaville)',
    iso2: 'cg',
    dialCode: '242',
    iso3: 178,
  },
  { name: 'Cook Islands', iso2: 'ck', dialCode: '682', iso3: 184 },
  { name: 'Costa Rica', iso2: 'cr', dialCode: '506', iso3: 188 },
  { name: 'Côte d’Ivoire', iso2: 'ci', dialCode: '225', iso3: 384 },
  { name: 'Croatia (Hrvatska)', iso2: 'hr', dialCode: '385', iso3: 191 },
  { name: 'Cuba', iso2: 'cu', dialCode: '53', iso3: 192 },
  { name: 'Curaçao', iso2: 'cw', dialCode: '599', iso3: 531 },
  { name: 'Cyprus (Κύπρος)', iso2: 'cy', dialCode: '357', iso3: 196 },
  {
    name: 'Czech Republic (Česká republika)',
    iso2: 'cz',
    dialCode: '420',
    iso3: 203,
  },
  { name: 'Denmark (Danmark)', iso2: 'dk', dialCode: '45', iso3: 208 },
  { name: 'Djibouti', iso2: 'dj', dialCode: '253', iso3: 262 },
  { name: 'Dominica', iso2: 'dm', dialCode: '1767', iso3: 212 },
  {
    name: 'Dominican Republic (República Dominicana)',
    iso2: 'do',
    dialCode: '1809',
    iso3: 214,
  },
  { name: 'Ecuador', iso2: 'ec', dialCode: '593', iso3: 218 },
  { name: 'Egypt (‫مصر‬‎)', iso2: 'eg', dialCode: '20', iso3: 818 },
  { name: 'El Salvador', iso2: 'sv', dialCode: '503', iso3: 222 },
  {
    name: 'Equatorial Guinea (Guinea Ecuatorial)',
    iso2: 'gq',
    dialCode: '240',
    iso3: 226,
  },
  { name: 'Eritrea', iso2: 'er', dialCode: '291', iso3: 232 },
  { name: 'Estonia (Eesti)', iso2: 'ee', dialCode: '372', iso3: 233 },
  { name: 'Ethiopia', iso2: 'et', dialCode: '251', iso3: 231 },
  {
    name: 'Falkland Islands (Islas Malvinas)',
    iso2: 'fk',
    dialCode: '500',
    iso3: 238,
  },
  { name: 'Faroe Islands (Føroyar)', iso2: 'fo', dialCode: '298', iso3: 234 },
  { name: 'Fiji', iso2: 'fj', dialCode: '679', iso3: 242 },
  { name: 'Finland (Suomi)', iso2: 'fi', dialCode: '358', iso3: 246 },
  { name: 'France', iso2: 'fr', dialCode: '33', iso3: 250 },
  {
    name: 'French Guiana (Guyane française)',
    iso2: 'gf',
    dialCode: '594',
    iso3: 254,
  },
  {
    name: 'French Polynesia (Polynésie française)',
    iso2: 'pf',
    dialCode: '689',
    iso3: 258,
  },
  {
    name: 'French Southern Territories',
    iso2: 'tf',
    dialCode: '262',
    iso3: 260,
  },
  { name: 'Gabon', iso2: 'ga', dialCode: '241', iso3: 266 },
  { name: 'Gambia', iso2: 'gm', dialCode: '220', iso3: 270 },
  { name: 'Georgia (საქართველო)', iso2: 'ge', dialCode: '995', iso3: 268 },
  { name: 'Germany (Deutschland)', iso2: 'de', dialCode: '49', iso3: 276 },
  { name: 'Ghana (Gaana)', iso2: 'gh', dialCode: '233', iso3: 288 },
  { name: 'Gibraltar', iso2: 'gi', dialCode: '350', iso3: 292 },
  { name: 'Greece (Ελλάδα)', iso2: 'gr', dialCode: '30', iso3: 300 },
  {
    name: 'Greenland (Kalaallit Nunaat)',
    iso2: 'gl',
    dialCode: '299',
    iso3: 304,
  },
  { name: 'Grenada', iso2: 'gd', dialCode: '1473', iso3: 308 },
  { name: 'Guadeloupe', iso2: 'gp', dialCode: '590', iso3: 312 },
  { name: 'Guam', iso2: 'gu', dialCode: '1671', iso3: 316 },
  { name: 'Guatemala', iso2: 'gt', dialCode: '502', iso3: 320 },
  { name: 'Guernsey', iso2: 'gg', dialCode: '441481', iso3: 831 },
  { name: 'Guinea (Guinée)', iso2: 'gn', dialCode: '224', iso3: 324 },
  {
    name: 'Guinea-Bissau (Guiné Bissau)',
    iso2: 'gw',
    dialCode: '245',
    iso3: 624,
  },
  { name: 'Guyana', iso2: 'gy', dialCode: '592', iso3: 328 },
  { name: 'Haiti', iso2: 'ht', dialCode: '509', iso3: 332 },
  {
    name: 'Heard Island and McDonald Islands',
    iso2: 'hm',
    dialCode: '672',
    iso3: 334,
  },
  { name: 'Honduras', iso2: 'hn', dialCode: '504', iso3: 340 },
  { name: 'Hong Kong (香港)', iso2: 'hk', dialCode: '852', iso3: 344 },
  { name: 'Hungary (Magyarország)', iso2: 'hu', dialCode: '36', iso3: 348 },
  { name: 'Iceland (Ísland)', iso2: 'is', dialCode: '354', iso3: 352 },
  { name: 'India (भारत)', iso2: 'in', dialCode: '91', iso3: 356 },
  { name: 'Indonesia', iso2: 'id', dialCode: '62', iso3: 360 },
  { name: 'Iran (‫ایران‬‎)', iso2: 'ir', dialCode: '98', iso3: 364 },
  { name: 'Iraq (‫العراق‬‎)', iso2: 'iq', dialCode: '964', iso3: 368 },
  { name: 'Ireland', iso2: 'ie', dialCode: '353', iso3: 372 },
  { name: 'Isle of Man', iso2: 'im', dialCode: '441624', iso3: 833 },
  { name: 'Israel (‫ישראל‬‎)', iso2: 'il', dialCode: '972', iso3: 376 },
  { name: 'Italy (Italia)', iso2: 'it', dialCode: '39', iso3: 380 },
  { name: 'Jamaica', iso2: 'jm', dialCode: '1876', iso3: 388 },
  { name: 'Japan (日本)', iso2: 'jp', dialCode: '81', iso3: 392 },
  { name: 'Jersey', iso2: 'je', dialCode: '441534', iso3: 832 },
  { name: 'Jordan (‫الأردن‬‎)', iso2: 'jo', dialCode: '962', iso3: 400 },
  { name: 'Kazakhstan (Казахстан)', iso2: 'kz', dialCode: '76', iso3: 398 },
  { name: 'Kenya', iso2: 'ke', dialCode: '254', iso3: 404 },
  { name: 'Kiribati', iso2: 'ki', dialCode: '686', iso3: 296 },
  { name: 'Kosovo', iso2: 'xk', dialCode: '383', iso3: 383 },
  { name: 'Kuwait (‫الكويت‬‎)', iso2: 'kw', dialCode: '965', iso3: 414 },
  { name: 'Kyrgyzstan (Кыргызстан)', iso2: 'kg', dialCode: '996', iso3: 417 },
  { name: 'Laos (ລາວ)', iso2: 'la', dialCode: '856', iso3: 418 },
  { name: 'Latvia (Latvija)', iso2: 'lv', dialCode: '371', iso3: 428 },
  { name: 'Lebanon (‫لبنان‬‎)', iso2: 'lb', dialCode: '961', iso3: 422 },
  { name: 'Labuan', iso2: 'lab', dialCode: '60', iso3: 458 }, // Not a country in ISO 3166-1, added iso code of Malaysia
  { name: 'Lesotho', iso2: 'ls', dialCode: '266', iso3: 426 },
  { name: 'Liberia', iso2: 'lr', dialCode: '231', iso3: 430 },
  { name: 'Libya (‫ليبيا‬‎)', iso2: 'ly', dialCode: '218', iso3: 434 },
  { name: 'Liechtenstein', iso2: 'li', dialCode: '423', iso3: 438 },
  { name: 'Lithuania (Lietuva)', iso2: 'lt', dialCode: '370', iso3: 440 },
  { name: 'Luxembourg', iso2: 'lu', dialCode: '352', iso3: 442 },
  { name: 'Macau (澳門)', iso2: 'mo', dialCode: '853', iso3: 446 },
  {
    name: 'Macedonia (FYROM) (Македонија)',
    iso2: 'mk',
    dialCode: '389',
    iso3: 807,
  },
  { name: 'Madagascar (Madagasikara)', iso2: 'mg', dialCode: '261', iso3: 450 },
  { name: 'Malawi', iso2: 'mw', dialCode: '265', iso3: 454 },
  { name: 'Malaysia', iso2: 'my', dialCode: '60', iso3: 458 },
  { name: 'Maldives', iso2: 'mv', dialCode: '960', iso3: 462 },
  { name: 'Mali', iso2: 'ml', dialCode: '223', iso3: 466 },
  { name: 'Malta', iso2: 'mt', dialCode: '356', iso3: 470 },
  { name: 'Marshall Islands', iso2: 'mh', dialCode: '692', iso3: 584 },
  { name: 'Martinique', iso2: 'mq', dialCode: '596', iso3: 474 },
  { name: 'Mauritania (‫موريتانيا‬‎)', iso2: 'mr', dialCode: '222', iso3: 478 },
  { name: 'Mauritius (Moris)', iso2: 'mu', dialCode: '230', iso3: 480 },
  { name: 'Mayotte', iso2: 'yt', dialCode: '262', iso3: 175 },
  { name: 'Mexico (México)', iso2: 'mx', dialCode: '52', iso3: 484 },
  { name: 'Micronesia', iso2: 'fm', dialCode: '691', iso3: 583 },
  {
    name: 'Moldova (Republica Moldova)',
    iso2: 'md',
    dialCode: '373',
    iso3: 498,
  },
  { name: 'Monaco', iso2: 'mc', dialCode: '377', iso3: 492 },
  { name: 'Mongolia (Монгол)', iso2: 'mn', dialCode: '976', iso3: 496 },
  { name: 'Montenegro (Crna Gora)', iso2: 'me', dialCode: '382', iso3: 499 },
  { name: 'Montserrat', iso2: 'ms', dialCode: '1664', iso3: 500 },
  { name: 'Morocco (‫المغرب‬‎)', iso2: 'ma', dialCode: '212', iso3: 504 },
  { name: 'Mozambique (Moçambique)', iso2: 'mz', dialCode: '258', iso3: 508 },
  { name: 'Myanmar (Burma) (မြန်မာ)', iso2: 'mm', dialCode: '95', iso3: 104 },
  { name: 'Namibia (Namibië)', iso2: 'na', dialCode: '264', iso3: 516 },
  { name: 'Nauru', iso2: 'nr', dialCode: '674', iso3: 520 },
  { name: 'Nepal (नेपाल)', iso2: 'np', dialCode: '977', iso3: 524 },
  { name: 'Netherlands (Nederland)', iso2: 'nl', dialCode: '31', iso3: 528 },
  {
    name: 'New Caledonia (Nouvelle-Calédonie)',
    iso2: 'nc',
    dialCode: '687',
    iso3: 540,
  },
  { name: 'New Zealand', iso2: 'nz', dialCode: '64', iso3: 554 },
  { name: 'Nicaragua', iso2: 'ni', dialCode: '505', iso3: 558 },
  { name: 'Niger (Nijar)', iso2: 'ne', dialCode: '227', iso3: 562 },
  { name: 'Nigeria', iso2: 'ng', dialCode: '234', iso3: 566 },
  { name: 'Niue', iso2: 'nu', dialCode: '683', iso3: 570 },
  { name: 'Norfolk Island', iso2: 'nf', dialCode: '672', iso3: 574 },
  {
    name: 'North Korea (조선 민주주의 인민 공화국)',
    iso2: 'kp',
    dialCode: '850',
    iso3: 408,
  },
  { name: 'Northern Mariana Islands', iso2: 'mp', dialCode: '1670', iso3: 580 },
  { name: 'Norway (Norge)', iso2: 'no', dialCode: '47', iso3: 578 },
  { name: 'Oman (‫عُمان‬‎)', iso2: 'om', dialCode: '968', iso3: 512 },
  { name: 'Pakistan (‫پاکستان‬‎)', iso2: 'pk', dialCode: '92', iso3: 586 },
  { name: 'Palau', iso2: 'pw', dialCode: '680', iso3: 585 },
  { name: 'Palestine (‫فلسطين‬‎)', iso2: 'ps', dialCode: '970', iso3: 275 },
  { name: 'Panama (Panamá)', iso2: 'pa', dialCode: '507', iso3: 591 },
  { name: 'Papua New Guinea', iso2: 'pg', dialCode: '675', iso3: 598 },
  { name: 'Paraguay', iso2: 'py', dialCode: '595', iso3: 600 },
  { name: 'Peru (Perú)', iso2: 'pe', dialCode: '51', iso3: 604 },
  { name: 'Pitcairn', iso2: 'pn', dialCode: '64', iso3: 612 },
  { name: 'Philippines', iso2: 'ph', dialCode: '63', iso3: 608 },
  { name: 'Poland (Polska)', iso2: 'pl', dialCode: '48', iso3: 616 },
  { name: 'Portugal', iso2: 'pt', dialCode: '351', iso3: 620 },
  { name: 'Puerto Rico', iso2: 'pr', dialCode: '1787', iso3: 630 },
  { name: 'Qatar (‫قطر‬‎)', iso2: 'qa', dialCode: '974', iso3: 634 },
  { name: 'Réunion (La Réunion)', iso2: 're', dialCode: '262', iso3: 638 },
  { name: 'Romania (România)', iso2: 'ro', dialCode: '40', iso3: 642 },
  { name: 'Russia (Россия)', iso2: 'ru', dialCode: '7', iso3: 643 },
  { name: 'Rwanda', iso2: 'rw', dialCode: '250', iso3: 646 },
  { name: 'Saint Barthélemy', iso2: 'bl', dialCode: '590', iso3: 652 },
  { name: 'Saint Helena', iso2: 'sh', dialCode: '290', iso3: 654 },
  { name: 'Saint Kitts and Nevis', iso2: 'kn', dialCode: '1869', iso3: 659 },
  { name: 'Saint Lucia', iso2: 'lc', dialCode: '1758', iso3: 662 },
  {
    name: 'Saint Martin (Saint-Martin (partie française))',
    iso2: 'mf',
    dialCode: '590',
    iso3: 663,
  },
  {
    name: 'Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)',
    iso2: 'pm',
    dialCode: '508',
    iso3: 666,
  },
  {
    name: 'Saint Vincent and the Grenadines',
    iso2: 'vc',
    dialCode: '1784',
    iso3: 670,
  },
  { name: 'Samoa', iso2: 'ws', dialCode: '685', iso3: 882 },
  { name: 'San Marino', iso2: 'sm', dialCode: '378', iso3: 674 },
  {
    name: 'São Tomé and Príncipe (São Tomé e Príncipe)',
    iso2: 'st',
    dialCode: '239',
    iso3: 678,
  },
  {
    name: 'Saudi Arabia (‫المملكة العربية السعودية‬‎)',
    iso2: 'sa',
    dialCode: '966',
    iso3: 682,
  },
  { name: 'Senegal (Sénégal)', iso2: 'sn', dialCode: '221', iso3: 686 },
  { name: 'Serbia (Србија)', iso2: 'rs', dialCode: '381', iso3: 688 },
  { name: 'Seychelles', iso2: 'sc', dialCode: '248', iso3: 690 },
  { name: 'Sierra Leone', iso2: 'sl', dialCode: '232', iso3: 694 },
  { name: 'Singapore', iso2: 'sg', dialCode: '65', iso3: 702 },
  { name: 'Sint Maarten', iso2: 'sx', dialCode: '1721', iso3: 534 },
  { name: 'Slovakia (Slovensko)', iso2: 'sk', dialCode: '421', iso3: 703 },
  { name: 'Slovenia (Slovenija)', iso2: 'si', dialCode: '386', iso3: 705 },
  { name: 'Solomon Islands', iso2: 'sb', dialCode: '677', iso3: 90 },
  { name: 'Somalia (Soomaaliya)', iso2: 'so', dialCode: '252', iso3: 706 },
  { name: 'South Africa', iso2: 'za', dialCode: '27', iso3: 710 },
  {
    name: 'South Georgia and the South Sandwich Islands',
    iso2: 'gs',
    dialCode: '500',
    iso3: 239,
  },
  { name: 'South Korea (대한민국)', iso2: 'kr', dialCode: '82', iso3: 410 },
  {
    name: 'South Sudan (‫جنوب السودان‬‎)',
    iso2: 'ss',
    dialCode: '211',
    iso3: 728,
  },
  { name: 'Spain (España)', iso2: 'es', dialCode: '34', iso3: 724 },
  { name: 'Sri Lanka (ශ්‍රී ලංකාව)', iso2: 'lk', dialCode: '94', iso3: 144 },
  { name: 'Sudan (‫السودان‬‎)', iso2: 'sd', dialCode: '249', iso3: 729 },
  { name: 'Suriname', iso2: 'sr', dialCode: '597', iso3: 740 },
  { name: 'Svalbard and Jan Mayen', iso2: 'sj', dialCode: '47', iso3: 744 },
  { name: 'Swaziland', iso2: 'sz', dialCode: '268', iso3: 748 },
  { name: 'Sweden (Sverige)', iso2: 'se', dialCode: '46', iso3: 752 },
  { name: 'Switzerland (Schweiz)', iso2: 'ch', dialCode: '41', iso3: 756 },
  { name: 'Syria (‫سوريا‬‎)', iso2: 'sy', dialCode: '963', iso3: 760 },
  { name: 'Taiwan (台灣)', iso2: 'tw', dialCode: '886', iso3: 158 },
  { name: 'Tajikistan', iso2: 'tj', dialCode: '992', iso3: 762 },
  { name: 'Tanzania', iso2: 'tz', dialCode: '255', iso3: 834 },
  { name: 'Thailand (ไทย)', iso2: 'th', dialCode: '66', iso3: 764 },
  { name: 'Timor-Leste', iso2: 'tl', dialCode: '670', iso3: 626 },
  { name: 'Togo', iso2: 'tg', dialCode: '228', iso3: 768 },
  { name: 'Tokelau', iso2: 'tk', dialCode: '690', iso3: 772 },
  { name: 'Tonga', iso2: 'to', dialCode: '676', iso3: 776 },
  { name: 'Trinidad and Tobago', iso2: 'tt', dialCode: '1868', iso3: 780 },
  { name: 'Tunisia (‫تونس‬‎)', iso2: 'tn', dialCode: '216', iso3: 788 },
  { name: 'Turkey (Türkiye)', iso2: 'tr', dialCode: '90', iso3: 792 },
  { name: 'Turkmenistan', iso2: 'tm', dialCode: '993', iso3: 795 },
  { name: 'Turks and Caicos Islands', iso2: 'tc', dialCode: '1649', iso3: 796 },
  { name: 'Tuvalu', iso2: 'tv', dialCode: '688', iso3: 798 },
  { name: 'U.S. Virgin Islands', iso2: 'vi', dialCode: '1340', iso3: 850 },
  { name: 'Uganda', iso2: 'ug', dialCode: '256', iso3: 800 },
  { name: 'Ukraine (Україна)', iso2: 'ua', dialCode: '380', iso3: 804 },
  {
    name: 'United Arab Emirates (‫الإمارات العربية المتحدة‬‎)',
    iso2: 'ae',
    dialCode: '971',
    iso3: 784,
  },
  { name: 'United Kingdom', iso2: 'gb', dialCode: '44', iso3: 826 },
  { name: 'United States', iso2: 'us', dialCode: '1', iso3: 840 },
  {
    name: 'United States Minor Outlying Islands',
    iso2: 'um',
    dialCode: '246',
    iso3: 581,
  },
  { name: 'Uruguay', iso2: 'uy', dialCode: '598', iso3: 858 },
  { name: 'Uzbekistan (Oʻzbekiston)', iso2: 'uz', dialCode: '998', iso3: 860 },
  { name: 'Vanuatu', iso2: 'vu', dialCode: '678', iso3: 548 },
  {
    name: 'Vatican City (Città del Vaticano)',
    iso2: 'va',
    dialCode: '39',
    iso3: 336,
  },
  { name: 'Venezuela', iso2: 've', dialCode: '58', iso3: 862 },
  { name: 'Vietnam (Việt Nam)', iso2: 'vn', dialCode: '84', iso3: 704 },
  {
    name: 'Wallis and Futuna (Wallis-et-Futuna)',
    iso2: 'wf',
    dialCode: '681',
    iso3: 876,
  },
  {
    name: 'Western Sahara (‫الصحراء الغربية‬‎)',
    iso2: 'eh',
    dialCode: '212',
    iso3: 732,
  },
  { name: 'Yemen (‫اليمن‬‎)', iso2: 'ye', dialCode: '967', iso3: 887 },
  { name: 'Zambia', iso2: 'zm', dialCode: '260', iso3: 894 },
  { name: 'Zimbabwe', iso2: 'zw', dialCode: '263', iso3: 716 },
  { name: 'Åland Islands', iso2: 'ax', dialCode: '358', iso3: 248 },
];
