export function getFlagEmoji(countryCode: string): string {
  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map(char => 127397 + char.charCodeAt(0));
  return String.fromCodePoint(...codePoints);
}

export function isEmojiFlagSupported(): boolean {
  return flagEmojiSupport();
}

// @see https://github.com/danalloway/detect-emoji-support/blob/master/lib/index.js
let flagEmojiSupportCache: boolean | undefined = undefined;
export function flagEmojiSupport(): boolean {
  if (flagEmojiSupportCache !== undefined) {
    return flagEmojiSupportCache;
  }

  if (typeof window === 'undefined') {
    return false;
  }

  const node = window.document.createElement('canvas');
  const ctx = node.getContext('2d') as CanvasRenderingContext2D &
    Record<string, never>;
  if (!ctx) {
    return false;
  }

  const backingStoreRatio: number =
    ctx.webkitBackingStorePixelRatio ||
    ctx.mozBackingStorePixelRatio ||
    ctx.msBackingStorePixelRatio ||
    ctx.oBackingStorePixelRatio ||
    ctx.backingStorePixelRatio ||
    1;

  const offset = 12 * backingStoreRatio;

  ctx.fillStyle = '#f00';
  ctx.textBaseline = 'top';
  ctx.font = '32px Arial';
  ctx.fillText('\ud83c\udde6\ud83c\uddea', 0, 0); // @see https://flagpedia.net/the-united-arab-emirates/emoji

  const support = ctx.getImageData(offset, offset, 1, 1).data[0] !== 0;

  flagEmojiSupportCache = support;

  return support;
}
