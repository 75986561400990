import { baseApi } from '../../store/store.constants';

import type { Jurisdiction } from '@shared/master-types';
import type { PaginatedDocs } from 'payload/dist/mongoose/types';

const layoutApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: build => ({
    getJurisdictionList: build.query<
      PaginatedDocs<Jurisdiction> | null,
      undefined
    >({
      query: () => ({
        url: '/jurisdictions/?depth=3',
      }),
    }),
  }),
});

export const {
  endpoints: { getJurisdictionList },
  useGetJurisdictionListQuery,
} = layoutApi;
