import { baseApi } from '../../store/store.constants';

import type { Meta } from '@shared/master-types';

const metaApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: build => ({
    getMeta: build.query<Meta | null, { locale?: string } | undefined>({
      query: params => ({
        url: '/globals/meta?depth=1',
        params: params ?? undefined,
      }),
    }),
  }),
});

export const {
  endpoints: { getMeta },
  useGetMetaQuery,
} = metaApi;
