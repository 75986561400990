import { Page } from '@shared/master-types';

import { PaginatedDocs } from 'payload/dist/mongoose/types';
import { Where } from 'payload/types';

import { baseApi } from '../../store/store.constants';

export const pagesApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: build => ({
    getPages: build.query<
      PaginatedDocs<Page>,
      | {
          locale?: string;
          draft?: boolean;
          where?: Where;
          page?: number;
          limit?: number;
        }
      | undefined
    >({
      query: params => ({
        url: '/pages',
        params: params ?? undefined,
      }),
    }),
  }),
});

export const {
  endpoints: { getPages },
  useGetPagesQuery,
} = pagesApi;
