import { createContext, useContext, ReactNode, ComponentType } from 'react';

import type { TSectionWrapperProps } from '../types';

export type TSectionContext = {
  sectionProps: TSectionWrapperProps;
};

export const SectionContext = createContext<TSectionContext | null>(null);

export const useSection = (): TSectionContext => {
  const data = useContext(SectionContext);

  if (!data) {
    throw new Error('invalid section context');
  }

  return data;
};

type TServerContextDataProviderProps = {
  sectionProps: TSectionWrapperProps;
  children: ReactNode;
};
export const SectionProvider: ComponentType<
  TServerContextDataProviderProps
> = ({ sectionProps, children }) => {
  const contextValue = {
    sectionProps,
  };

  return (
    <SectionContext.Provider value={contextValue}>
      {children}
    </SectionContext.Provider>
  );
};
