import { PaginatedDocs } from 'payload/dist/mongoose/types';

import { baseApi } from '../../../../store/store.constants';

import type { HeaderDropdown } from '@shared/master-types';

const megamenuApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: build => ({
    getHeaderDropdowns: build.query<
      PaginatedDocs<HeaderDropdown>,
      { ids: string[]; depth?: number; locale?: string } | undefined
    >({
      query: params => ({
        url: `/header-dropdown/?where[id][in]=${params?.ids.join(',')}&depth=${
          params?.depth || 1
        }`,
        params: params ?? undefined,
      }),
    }),
  }),
});

export const {
  endpoints: { getHeaderDropdowns },
  useGetHeaderDropdownsQuery,
} = megamenuApi;
