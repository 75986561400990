import { isMobile } from '@front/master';
import {
  Container,
  ImageWithPlaceholder,
  RichText,
  toImageProps,
  TRichTextContent,
} from '@front/shared/ds';
import { Media } from '@shared/master-types';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useIntersection } from 'react-use';

export type TMobileAppRow = {
  id?: string;
  blockType?: 'mobile-app';
  blockName?: string;
  box: {
    id?: string;
    title?: string;
    img: Media;
  }[];
  text: TRichTextContent;
};

type TMobileAppRowProps = {
  row: TMobileAppRow;
};

export const MobileAppRow: React.FC<TMobileAppRowProps> = ({ row }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);
  const ref = React.useRef<HTMLDivElement>(null);
  const intersection = useIntersection(ref, { threshold: 0.5 });

  useEffect(() => {
    if (intersection && intersection.intersectionRatio > 0) {
      setIsVisible(true);
    }
    setIsDesktop(!isMobile());
  }, [intersection]);

  return (
    <Container overflowHidden>
      <div className='flex flex-col gap-8 xl:gap-16'>
        {row.box && (
          <div>
            <div>
              <div className='scrollbar-hidden -mx-4 overflow-scroll scroll-smooth xl:mx-0 xl:px-0'>
                <div className='box-content flex max-h-[44rem] min-w-[46.25rem] flex-nowrap gap-2 px-4 md:min-w-[77rem] md:justify-center xl:max-h-full xl:gap-4 2xl:box-border'>
                  {row.box.map((item, index) => {
                    const imageProps = toImageProps(item.img);
                    if (!imageProps) return null;

                    return (
                      <motion.div
                        initial={{
                          opacity: isDesktop ? 0 : 1,
                          y: isDesktop ? 20 : 0,
                        }}
                        animate={
                          isDesktop
                            ? {
                                opacity: isVisible ? 1 : 0,
                                y: isVisible ? 0 : 20,
                              }
                            : {}
                        }
                        transition={
                          isDesktop ? { duration: 0.5, delay: index * 0.4 } : {}
                        }
                        className={clsx(
                          'flex w-[18.5rem] gap-[1.188rem] md:gap-8',
                          index % 2 === 0
                            ? 'flex-col justify-start'
                            : 'flex-col-reverse justify-end pt-[1.438rem] xl:pt-10',
                        )}
                        ref={ref}
                        key={item.id}
                      >
                        <ImageWithPlaceholder
                          className='block h-[22.5rem] w-full rounded-3xl object-cover md:h-[38rem]'
                          {...imageProps}
                          alt={imageProps.alt}
                        />
                        {item.title && (
                          <h3 className='leading-4 hyphens-auto text-center text-[0.6rem] text-light-interface-800 md:text-base md:leading-mega-loose'>
                            {item.title}
                          </h3>
                        )}
                      </motion.div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        )}
        {row.text && (
          <RichText
            className='flex flex-col gap-4 text-center text-3xl font-normal leading-extra-loose text-interface-1000 lg:gap-8'
            content={row.text}
          />
        )}
      </div>
    </Container>
  );
};
