import {
  EModalBackground,
  EModalPadding,
  Modal as ModalV1,
  ModalV2,
  EModalVariant,
  RichText,
  isRichTextContentEmpty,
} from '@front/shared/ds';
import { cva } from 'cva';
import { MouseEvent, useEffect, useRef, FC } from 'react';

import { EModalsRootType } from './ModalsRoot.constants';
import { useGetModalQuery } from '../../../features/modals/getModal.query';
import { useModalsControl } from '../../../features/modals/useModalsControl.hook';
import { useLocale } from '../../../hooks';
import { EFormInputParserVariant } from '../../sections/FormInputParser';

import { ModalForm } from './ModalForm';
import { ModalFormV2 } from './ModalFormV2';
import { ModalMultistepForm } from './ModalMultistepForm';

const modalClass = cva(
  'w-full bg-transparent p-0 outline-none backdrop:bg-interface-1000/70',
  {
    variants: {
      modalFormType: {
        [EModalsRootType.Single]: '',
        [EModalsRootType.Multistep]: '',
      },
      modalVariant: {
        [EModalVariant.V1]: 'max-w-[100vw]',
        [EModalVariant.V2]: '',
      },
    },
    compoundVariants: [
      {
        modalFormType: EModalsRootType.Single,
        modalVariant: EModalVariant.V1,
        class: 'lg:max-w-md',
      },
      {
        modalFormType: EModalsRootType.Multistep,
        modalVariant: EModalVariant.V1,
        class: 'lg:max-w-[540px] 2xl:px-4',
      },
      {
        modalFormType: [EModalsRootType.Single, EModalsRootType.Multistep],
        modalVariant: EModalVariant.V2,
        class:
          'max-w-[27.5rem] px-4 tablet:max-w-[38rem] desktop:max-w-[38rem]',
      },
    ],
  },
);

const ModalsRoot: FC = () => {
  const { locale } = useLocale();
  const { currentModalId, closeModal } = useModalsControl();
  const { data: modal } = useGetModalQuery(
    { id: String(currentModalId), locale },
    { skip: !currentModalId },
  );

  const modalRef = useRef<HTMLDialogElement>(null);
  const modalFormType = typeof modal?.form !== 'string' && modal?.form?.type;
  const modalBackground = modal?.background as EModalBackground;
  const modalPadding =
    modalFormType === EModalsRootType.Multistep
      ? EModalPadding.Medium
      : EModalPadding.Large;

  useEffect(() => {
    if (modalRef.current) {
      if (
        currentModalId &&
        modal?.id === currentModalId &&
        !modalRef.current.open
      ) {
        modalRef.current.showModal();
      }
    }
  }, [currentModalId, modal]);

  const handleCloseModal = (event: MouseEvent<HTMLDialogElement>): void => {
    if (event.target === event.currentTarget) {
      closeModal();
    }
  };

  let inputTheme = EFormInputParserVariant.Secondary;
  if (modalBackground === EModalBackground.White) {
    inputTheme = EFormInputParserVariant.Primary;
  }

  const scrollBarClasses =
    'scrollbar scrollbar-w-1 scrollbar-thumb-interface-500 scrollbar-track-control-200 scroll-smooth';

  const Modal = modal?.variant === EModalVariant.V2 ? ModalV2 : ModalV1;

  if (!currentModalId || modal?.id !== currentModalId) {
    return null;
  }

  return (
    <dialog
      ref={modalRef}
      className={modalClass({
        modalFormType:
          modalFormType === EModalsRootType.Multistep
            ? EModalsRootType.Multistep
            : EModalsRootType.Single,
        modalVariant: (modal?.variant as EModalVariant) ?? EModalVariant.V1,
        className: scrollBarClasses,
      })}
      onClose={closeModal}
      onClick={handleCloseModal}
    >
      <Modal
        title={modal.title}
        onClose={closeModal}
        padding={modalPadding}
        modalBackground={modalBackground}
        description={
          modal.description && !isRichTextContentEmpty(modal.description) ? (
            <RichText content={modal.description} />
          ) : null
        }
      >
        {modal.form && typeof modal.form !== 'string' && (
          <>
            {modal.form.type === EModalsRootType.Single &&
              modal?.variant !== EModalVariant.V2 && (
                <ModalForm
                  form={modal.form}
                  additionalText={modal.additionalText}
                  onSubmit={closeModal}
                  inputTheme={inputTheme}
                />
              )}
            {modal.form.type === EModalsRootType.Single &&
              modal?.variant === EModalVariant.V2 && (
                <ModalFormV2 form={modal.form} onSubmit={closeModal} />
              )}
            {modal.form.type === EModalsRootType.Multistep && (
              <ModalMultistepForm
                form={modal.form}
                additionalText={modal.additionalText}
                onSubmit={closeModal}
                inputTheme={inputTheme}
              />
            )}
          </>
        )}
      </Modal>
    </dialog>
  );
};

export default ModalsRoot;
