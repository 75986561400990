import { Setting } from '@shared/master-types';

import { baseApi } from '../../store/store.constants';

const globalSettingsApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: build => ({
    getSettings: build.query<
      Setting,
      { locale?: string; depth?: number } | undefined
    >({
      query: params => ({
        url: '/globals/settings',
        params,
      }),
    }),
  }),
});

export const {
  endpoints: { getSettings },
  useGetSettingsQuery,
} = globalSettingsApi;
